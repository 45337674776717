import { BaseURL } from "../BaseURL";

export const AddSpots = async (
   accessToken,
   vehicleSpotsName,
   vehicleSpotsTypeID,
   vehicleSpotsSize,
   vehicleSpotsWeight,
   vehicleSpotsWidth,
   image
) => {
   const form_data = new FormData();
   form_data.append("name", vehicleSpotsName);
   form_data.append("category", vehicleSpotsTypeID);
   form_data.append("length", vehicleSpotsSize);
   form_data.append("height", vehicleSpotsWeight);
   form_data.append("width", vehicleSpotsWidth);
   form_data.append("spot_image", image);
   const response = await fetch(`${BaseURL}/admin/parking-spot`, {
      body: form_data,
      method: "POST",
      headers: {
         Authorization: "Bearer" + "  " + accessToken,
         "Access-Control-Allow-Origin": "*",
      },
   });
   const result = await response.json();
   return result;
};

export const getVehicleSpotsType = async (accessToken) => {
   const response = await fetch(
      `${BaseURL}/admin/parking-vehicle-categories/all`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};
export const getVehicleSpots = async (accessToken, page) => {
   const response = await fetch(`${BaseURL}/admin/parking-spot?page=${page}`, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
         "Access-Control-Allow-Origin": "*",
      },
   });
   const result = await response.json();
   return result;
};
export const EditVehicleSpots = async (
   accessToken,
   vehicleSpotsName,
   vehicleSpotsTypeID,
   vehicleSpotsSize,
   vehicleSpotsWeight,
   vehicleSpotsWidth,
   id,
   image
) => {
   const form_data = new FormData();
   form_data.append("name", vehicleSpotsName);
   form_data.append("category", vehicleSpotsTypeID);
   form_data.append("length", vehicleSpotsSize);
   form_data.append("height", vehicleSpotsWeight);
   form_data.append("width", vehicleSpotsWidth);
   form_data.append("spot_image", image);
   const response = await fetch(`${BaseURL}/admin/parking-spot/${id}`, {
      body: form_data,
      method: "POST",
      headers: {
         Authorization: "Bearer" + "  " + accessToken,
         "Access-Control-Allow-Origin": "*",
      },
   });
   const result = await response.json();

   return result;
};

export const deleteVehicleSpots = async (accessToken, id) => {
   const response = await fetch(`${BaseURL}/admin/parking-spot/${id}`, {
      method: "DELETE",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
         "Access-Control-Allow-Origin": "*",
      },
   });
   const result = await response.json();
   return result;
};

export const setActiveSpots = async (accessToken, spotID) => {
   const response = await fetch(
      `${BaseURL}/admin/parking-spot/active/${spotID}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};
export const setDeActiveSpots = async (accessToken, spotID) => {
   const response = await fetch(
      `${BaseURL}/admin/parking-spot/deactive/${spotID}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};

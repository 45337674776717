import React, { Component } from "react";
import { Progress, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { getDeals, getGroups } from "../Services/AllGroups/index";
import moment from "moment";

class CardBox extends Component {
   constructor(props) {
      super(props);

      // this.SaveVehicle = this.SaveVehicle.bind(this);
      this.state = {
         dealID: "",
         date: "",
         itemId: "",
         price: "",
         dealTitle: "",
         dealDesc: "",
         dealImg: "",
         dealsArray: [],
         currentPage: "",
         lastPage: "",
         loading: false,
         groupsArray: [],
      };
   }

   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getAllDeals();
   }

   async getAllDeals(page) {
      let dealArray = [];

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const dealsAPI = await getDeals(accessToken, page);

         if (dealsAPI.status === true) {
            this.setState({ dealsArray: dealsAPI.deals.data });
            this.state.dealsArray.map((item, i) => {});
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async getAllGroups() {
      // let vehiclesArray = [];

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const groupsdata = await getGroups(accessToken);

         if (groupsdata.status === true) {
            this.setState({ groupsArray: groupsdata.groups.data });
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   paginate = (pageNumber) => {
      this.getDeals(pageNumber);
      window.scrollTo({
         top: 0,
         // left: 100,
         behavior: "smooth",
      });
      // window.scrollTo(0, 1000)
   };
   render() {
      return (
         <React.Fragment>
            {this.state.dealsArray.map((item, i) => {
               return (
                  <Card className="task-box col-12" key={i}>
                     <Progress
                        className="progress-sm animated-progess"
                        value={this.props.data["progressValue"]}
                        style={{ height: "3px" }}
                     ></Progress>
                     <p className=" badge text-end mt-3 ">
                        <span className="bg-primary p-2 rounded-circle">
                           {i + 1}
                        </span>
                     </p>

                     <CardBody className="borad-width">
                        {/* {this.props.id["id"]}  */}
                        <div className="float-end ms-2">
                           <div className="d-flex">
                              <i class="ri-calendar-2-line"></i>{" "}
                              <p className="ms-1">
                                 {this.getDate(item.updated_at)}
                              </p>
                              {/* {this.props.data["date"]} */}
                           </div>
                        </div>
                        <div className="mb-3">
                           <Link to="#" className="">
                              #{item.id} | $ {item.slot?.price ?? "2000"}
                              {/* {this.props.data["id"]} */}
                           </Link>
                        </div>
                        <div>
                           <h5 className="font-size-16">
                              <Link to="#" className="text-dark">
                                 {item.title}
                                 {/* {this.props.data["title"]} */}
                              </Link>
                           </h5>
                           <p className="mb-4">
                              {item.description}
                              {/* {this.props.data["subtitle"]} */}
                           </p>
                           <img
                              src={`${item.deal_image[0].folder_path}${item.deal_image[0].file_name}`}
                              // className="img-fluid"
                              style={{ height: "200px", width: "260px" }}
                           />
                        </div>

                        {/* <div className="d-inline-flex team mb-0">
              <div className="me-3 align-self-center">Team :</div>
              {this.props.data["team"].map((member, key) => (
                <div className="team-member" key={key}>
                  <Link to="# " className="team-member d-inline-block" id={"memberTooltip" + member.id}>
                    {member.img === "Null" ? (
                      <div className="avatar-xs">
                        <span className="avatar-title bg-soft-primary text-primary rounded-circle">{member.name.charAt(0)}</span>
                      </div>
                    ) : (
                      <img src={member.img} className="rounded-circle avatar-xs" alt="Nazox" />
                    )}
                  </Link>
                  <UncontrolledTooltip target={"memberTooltip" + member.id} placement="top">
                    {member.name}
                  </UncontrolledTooltip>
                </div>
              ))}
            </div> */}
                     </CardBody>
                  </Card>
               );
            })}
         </React.Fragment>
      );
   }
}

export default CardBox;

import { BaseURL } from "../BaseURL";

export const getPaymentMethods = async (accessToken) => {
  const response = await fetch(`${BaseURL}/admin/payment-method`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddPaymentMethod = async (accessToken, slectetPaymentId, paymentTitle) => {
  const response = await fetch(`${BaseURL}/admin/reports`, {
    body: JSON.stringify({
      title: paymentTitle,
      report_type: slectetPaymentId,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const ActivePaymentMethod = async (accessToken, paymentMethodAID) => {
  const response = await fetch(`${BaseURL}/admin/payment-method/payment-status/${paymentMethodAID}`, {
    body: JSON.stringify({
      status: "active",
      // report_type: slectetPaymentId,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeActivePaymentMethod = async (accessToken, paymentMethodDAID) => {
  const response = await fetch(`${BaseURL}/admin/payment-method/payment-status/${paymentMethodDAID}`, {
    body: JSON.stringify({
      status: "deactive",
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();

  return result;
};
export const EditLiveBoxFunction = async (accessToken, liveKey, liveSecret, editID) => {
  const response = await fetch(`${BaseURL}/admin/payment-method/${editID}`, {
    body: JSON.stringify({
      live_id: liveKey,
      live_secret: liveSecret,
      test_mode: false,
      // report_type: slectetPaymentId,
    }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();

  return result;
};
export const EditSandBoxFunction = async (accessToken, testKey, testSecret, editID) => {
  const response = await fetch(`${BaseURL}/admin/payment-method/${editID}`, {
    body: JSON.stringify({
      test_id: testKey,
      test_secret: testSecret,
      test_mode: true,
    }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

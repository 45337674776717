import React, { Component } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody } from "reactstrap";
import { getGroups } from "../Services/AllGroups/index";

class CardHeader extends Component {
  constructor(props) {
    super(props);

    // this.SaveVehicle = this.SaveVehicle.bind(this);
    this.state = {
      groupsArray: [],
      currentPage: "",
      lastPage: "",
      loading: false,
      groupAdd: false,
    };
  }

  async componentDidMount() {
    // Getting Models from API Call----------------------
    // this.getAllDeals();
    this.getAllGroups();
  }

  async getAllGroups() {
    // let vehiclesArray = [];

    try {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      const groupsdata = await getGroups(accessToken);

      if (groupsdata.status === true) {
        this.setState({ groupsArray: groupsdata.groups.data });
        // this.setState({groupAdd:true})
      }
    } catch (err) {
      console.log("error", err);
    }
  }
  render() {
    return (
      <React.Fragment>
        <CardBody>
          {/* <UncontrolledDropdown className="float-end "> */}
          {/* <DropdownToggle tag="i" style={{ cursor: "pointer" }} className="arrow-none">
              <i className="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
            </DropdownToggle> */}
          {/* <DropdownMenu className="dropdown-menu-end">
              <DropdownItem>Edit</DropdownItem>
              <DropdownItem>Delete</DropdownItem>
            </DropdownMenu> */}
          {/* </UncontrolledDropdown> */}
          {this.state.groupsArray.map((group, i) => {
            return (
              <>
                <h4 className="card-title">{group?.name}</h4>
                {group.slots.map((slot, index) => (
                  <>
                    {index === 0 ? <p className="w-75 h-25 bg-info">iusadkfs</p> : null}

                    {index >= 1 && index <= 2 ? (
                      <div className="d-flex justify-content-between flex-row">
                        <div className="w-25 h-25 bg-info">idsf </div>
                        <div className="&nbsp;w-25 h-25 bg-info">sadkfs</div>
                      </div>
                    ) : null}

                    {index === 3 ? <div className="w-75 h-25 bg-info">3rd</div> : ""}
                  </>
                ))}
                <div></div>
                {/* <h4 className="card-title">{this.props.title}</h4>
          <p className="mb-0">{this.props.columnsubtitle}</p> */}
              </>
            );
          })}
        </CardBody>
      </React.Fragment>
    );
  }
}

export default CardHeader;

import React from "react";
import ReactPaginate from "react-paginate";
import "./pagination.css";
const Pagination = ({ pageCount, paginate }) => {
   return (
      <>
         {/* {console.log("pagination page count", pageCount, "paginate", paginate)} */}
         <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={paginate}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
         />
      </>
   );
};

export default Pagination;

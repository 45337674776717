import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import { GetDashBoard } from "../../../src/components/Services/UserServices/Index";

class SalesAnalytics extends Component {
  state = {
    TotalDeals: "",
    OccupiedDeals: "",
    AvailableDeals: "",
    // series: [{ keya: this.state.TotalDeals }],
    series: [],
    options: {
      labels: ["TotalSlots", "OccupiedSlots", "AvailableSlots"],
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#5664d2", "#1cbb8c", "#eeb902"],
    },
  };
  async componentDidMount() {
    this.GetAllDashBoard();
  }
  async GetAllDashBoard() {
    let tokenAccess = localStorage.getItem("Token");
    let accessToken = JSON.parse(tokenAccess);

    try {
      const result = await GetDashBoard(accessToken);

      if (result.status === true) {
        this.setState({
          series: [result.total_deals, result.occupied_deals, result.available_deals],
        });
        this.setState({
          TotalDeals: result.total_deals,
          OccupiedDeals: result.occupied_deals,
          AvailableDeals: result.available_deals,
        });
      }
    } catch (err) {
      console.log("error ", err);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Card
          style={{
            //   marginTop: "2px",
            position: "sticky",
            //   marginTop:'50px',
            //   top: "1rem",
            //   height: "76vh"
          }}
        >
          <CardBody>
            <div className="float-end">
              {/* <select className="form-select form-select-sm">
                <option defaultValue>Apr</option>
                <option value="1">Mar</option>
                <option value="2">Feb</option>
                <option value="3">Jan</option>
              </select> */}
            </div>
            <h4 className="card-title mb-4">Slots Analytics</h4>

            <div id="donut-chart" className="apex-charts">
              <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="250" />
            </div>

            <Row className="mt-5">
              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>Total Slots
                  </p>
                  <h5>{this.state.TotalDeals}</h5>
                </div>
              </Col>

              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-success font-size-10 me-1"></i> Occupied Slots
                  </p>
                  <h5> {this.state.OccupiedDeals}</h5>
                </div>
              </Col>

              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-warning font-size-10 me-1"></i>
                    Available Slots
                  </p>
                  <h5>{this.state.AvailableDeals}</h5>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default SalesAnalytics;

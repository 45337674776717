import { BaseURL } from "../BaseURL";

export const GetQuickNotes = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/notes?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddQuickNotes = async (accessToken, description, quickImage) => {
  const form_data = new FormData();
  form_data.append("description", description);
  form_data.append("note_image", quickImage);

  const response = await fetch(`${BaseURL}/admin/notes`, {
    body: form_data,
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditQuickNotes = async (accessToken, description, quickImage, status, id) => {
  const form_data = new FormData();
  form_data.append("description", description);
  form_data.append("note_image", quickImage);
  form_data.append("status", status);

  const response = await fetch(`${BaseURL}/admin/notes/${id}`, {
    body: form_data,
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeleteQuickNotes = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/notes/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
// ..//////////////////// Quick notes  Activ deactive

export const ActiveQuickNotesID = async (accessToken, vehicleModelID) => {
  const response = await fetch(`${BaseURL}/admin/notes/active/${vehicleModelID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeActiveQuickNotesID = async (accessToken, vehicleModelID) => {
  const response = await fetch(`${BaseURL}/admin/notes/deactive/${vehicleModelID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
   getVehicleModel,
   AddModel,
   deleteVehicleModel,
   EditModel,
   SearchModel,
} from "../../Services/VehicleModel/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Swal from "sweetalert2";
class StripeSetting extends Component {
   constructor(props) {
      super(props);

      this.handleTypeOfListing = this.handleTypeOfListing.bind(this);
      this.ClearAllField = this.ClearAllField.bind(this);
      this.AllClearFields = this.AllClearFields.bind(this);
      this.SaveModel = this.SaveModel.bind(this);
      this.SaveLiveModel = this.SaveLiveModel.bind(this);
      this.state = {
         modelName: "",
         status: "",
         slug: "",
         modelNameVal: false,
         carModels: [],
         models: [],
         vehicleModel: "",
         editmodelID: "",
         currentPage: "",
         lastPage: "",
         loading: false,
         vehicleModelVal: false,
         typeOfListing: "sandBox",
         publicKey: "",
         secreteKey: "",
         publicKeyLive: "",
         secreteKeyLive: "",
         publicKeyVal: false,
         secreteKeyVal: false,
         publicKeyLiveVal: false,
         secreteKeyLiveVal: false,
      };
   }
   async SaveModel() {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      // publicKeyVal: "",
      // secreteKeyVal: "",
      // publicKeyLiveVal: "",
      // secreteKeyLiveVal: ""

      if (this.state.publicKey == "" || this.state.publicKey == undefined) {
         this.setState({ publicKeyVal: true });
      }
      if (this.state.secreteKey == "" || this.state.secreteKey == undefined) {
         this.setState({ secreteKeyVal: true });
      }
      if (
         this.state.publicKey !== "" &&
         this.state.publicKey !== undefined &&
         this.state.secreteKey !== "" &&
         this.state.secreteKey !== undefined
      ) {
         try {
            const response = await AddModel(
               accessToken,
               this.state.vehicleModel
            );
            console.log(response, "mmmmmmmmmmmm");
            if (response.status == true) {
               this.getAllModels();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle Model Added Successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleModel: "",
                  status: "",
                  editmodelID: "",
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: "Model has already been taken.",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async SaveLiveModel() {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      // publicKeyVal: "",
      // secreteKeyVal: "",
      // publicKeyLiveVal: "",
      // secreteKeyLiveVal: ""

      if (
         this.state.publicKeyLive == "" ||
         this.state.publicKeyLive == undefined
      ) {
         this.setState({ publicKeyLiveVal: true });
      }
      if (
         this.state.secreteKeyLive == "" ||
         this.state.secreteKeyLive == undefined
      ) {
         this.setState({ secreteKeyLiveVal: true });
      }
      if (
         this.state.publicKeyLive !== "" &&
         this.state.publicKeyLive !== undefined &&
         this.state.secreteKeyLive !== "" &&
         this.state.secreteKeyLive !== undefined
      ) {
         try {
            const response = await AddModel(
               accessToken,
               this.state.publicKeyLive
            );
            if (response.status == true) {
               this.getAllModels();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle Model Added Successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleModel: "",
                  status: "",
                  editmodelID: "",
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: "Model has already been taken.",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async updateModel() {
      if (this.state.modelName == "" || this.state.modelName == undefined) {
         this.setState({ modelNameVal: true });
      }

      if (this.state.modelName != "" && this.state.modelName != undefined) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditModel(
               accessToken,
               this.state.modelName,
               this.state.editmodelID
            );
            if (response.status == true) {
               this.getAllModels();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle Model Updated Successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  modelName: "",
                  status: "",
                  editmodelID: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   WhichTypeOfListingChange = async (e) => {
      if (e.target.id == "sandBox") {
         this.setState({
            typeOfListing: "sandBox",
         });
      } else if (e.target.id == "live") {
         this.setState({
            typeOfListing: "live",
         });
      }
   };
   WhichTypeOfListingChangeEdit = async (e) => {
      if (e.target.id == "sandBox") {
         this.setState({
            typeOfListing: "sandBox",
         });
      } else if (e.target.id == "live") {
         this.setState({
            typeOfListing: "live",
         });
      }
   };
   async setInitialValue(id, name, slug, status) {
      this.setState({
         editmodelID: id,
         modelName: name,
         slug: slug,

         status: status,
      });
   }

   async DeleteModelID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await deleteVehicleModel(accessToken, id);
            console.log(result);
            if (result.status === true) {
               this.getAllModels();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle Model Removed Successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async getAllModels(page) {
      let modelArray = [];

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const models = await getVehicleModel(accessToken, page);
         console.log("Gettting UserData ", models);

         if (models.status === true) {
            this.setState({ loading: true });
            this.setState({ currentPage: models.car_models.current_page });
            this.setState({ lastPage: models.car_models.last_page });
            this.setState({ carModels: models.car_models.data });
            this.state.carModels.map((mod, index) => {
               modelArray.push({
                  id: mod.id,
                  name: mod.name,
                  slug: mod.slug,
                  status: mod.status,
               });
               this.setState({ models: modelArray });
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async SearchChangeHandler(e) {
      // console.log(e.target.value)
      let search = e.target.value;
      // this.setState({searchItem:e.target.value})

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const vehicle = await SearchModel(accessToken, search);

         if (vehicle.status === true) {
            console.log("Cars ", vehicle);
            this.setState({ models: vehicle.vehicle_models.data });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   paginate = (pageNumber) => {
      this.getAllModels(pageNumber);
      window.scrollTo({
         top: 0,
         // left: 100,
         behavior: "smooth",
      });
      // window.scrollTo(0, 1000)
   };

   handleTypeOfListing = (e) => {
      switch (e.target.name) {
         case "publicKey":
            this.setState({
               publicKey: e.target.value,
            });
            if (e.target.value < 1) {
               this.setState({
                  publicKeyVal: true,
               });
            } else {
               this.setState({
                  publicKeyVal: false,
               });
            }
            break;
         case "secreteKey":
            this.setState({
               secreteKey: e.target.value,
            });
            if (e.target.value < 1) {
               this.setState({
                  secreteKeyVal: true,
               });
            } else {
               this.setState({
                  secreteKeyVal: false,
               });
            }
            break;
         case "publicKeyLive":
            this.setState({
               publicKeyLive: e.target.value,
            });
            if (e.target.value < 1) {
               this.setState({
                  publicKeyLiveVal: true,
               });
            } else {
               this.setState({
                  publicKeyLiveVal: false,
               });
            }
            break;
         case "secreteKeyLive":
            this.setState({
               secreteKeyLive: e.target.value,
            });
            if (e.target.value < 1) {
               this.setState({
                  secreteKeyLiveVal: true,
               });
            } else {
               this.setState({
                  secreteKeyLiveVal: false,
               });
            }
            break;

         default:
            break;
      }
   };

   ClearAllField = () => {
      this.setState({ vehicleModel: "" });
      this.setState({ vehicleModelVal: false });
   };
   AllClearFields = () => {};

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };

   render() {
      let paginationArray = [];
      for (let i = 1; i <= this.state.lastPage; i++) {
         paginationArray.push(i);
      }

      return (
         <>
            <div className="d-flex justify-content-between mt-3">
               <div>
                  {/* <div className="search-box "> */}
                  {/* <input
							type="text"
							class="form-control rounded-pill"
							placeholder="Search"
							aria-label="Search"
							aria-describedby="search-addon"
							onChange={(e) => this.SearchChangeHandler(e)}
						/> */}
                  {/* </div> */}
               </div>
               <div>
                  <Button
                     type="button"
                     onClick={this.ClearAllField}
                     data-bs-toggle="modal"
                     data-bs-target="#staticBackdropOne"
                     style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                     }}
                     className="btn-rounded mb-2 me-2"
                  >
                     <i
                        className="mdi mdi-plus me-1"
                        onClick={this.ClearAllField}
                     />{" "}
                     Add Stripe
                  </Button>
               </div>
            </div>
            <Container className="px-0 overflow-auto">
               <>
                  <Table className="table table-responsive">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Name</th>
                           <th scope="col">Date</th>
                           <th scope="col">Status</th>
                           <th scope="col">Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>21</td>
                           <td>Sand Box</td>

                           <td>04/1/2022</td>
                           <td>
                              <span class="badge bg-success">Active</span>
                           </td>
                           <td>
                              <Link to="#" className="me-3 text-primary">
                                 <i
                                    className="mdi mdi-pencil font-size-18"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropTwo"
                                 ></i>
                              </Link>
                              <Link to="#" className="text-danger">
                                 <i className="mdi mdi-trash-can font-size-18"></i>
                              </Link>
                           </td>
                        </tr>
                        <tr>
                           <td>22</td>
                           <td>Live</td>

                           <td>05/25/2020</td>
                           <td>
                              <span class="badge bg-danger">Deactive</span>
                           </td>
                           <td>
                              <Link to="#" className="me-3 text-primary">
                                 <i
                                    className="mdi mdi-pencil font-size-18"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropTwo"
                                 ></i>
                              </Link>
                              <Link to="#" className="text-danger">
                                 <i className="mdi mdi-trash-can font-size-18"></i>
                              </Link>
                           </td>
                        </tr>
                     </tbody>
                  </Table>
               </>
               {/* ) : (
						<div
							className="text-center d-flex align-items-center justify-content-center "
							style={{ height: "50vh" }}
						>
							<div class="spinner-border text-enter" role="status">
								<span class="visually-hidden"></span>
							</div>
						</div>
					)} */}
            </Container>
            {/* ///////////////////////add model */}
            <div
               className="modal fade"
               id="staticBackdropOne"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Add{" "}
                           {this.state.typeOfListing == "sandBox"
                              ? "Sandbox"
                              : "Live"}
                        </h5>
                        <button
                           type="button"
                           onClick={this.ClearAllField}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body w-100">
                        <div className="d-flex justify-content-start text-dark">
                           <div className="100 mx-2">
                              <div className="d-flex justify-content-center">
                                 <div className="form-check">
                                    <input
                                       className="form-check-input"
                                       type="radio"
                                       name="flexRadioDefault"
                                       id="sandBox"
                                       onClick={(e) =>
                                          this.WhichTypeOfListingChange(e)
                                       }
                                       defaultChecked={
                                          this.state.typeOfListing == "sandBox"
                                             ? true
                                             : false
                                       }
                                    />
                                    <label
                                       className="form-check-label me-3"
                                       htmlFor="sandBox"
                                    >
                                       Sand Box
                                    </label>
                                 </div>

                                 <div className="form-check">
                                    <input
                                       className="form-check-input"
                                       type="radio"
                                       name="flexRadioDefault"
                                       id="live"
                                       onClick={(e) =>
                                          this.WhichTypeOfListingChange(e)
                                       }
                                       defaultChecked={
                                          this.state.typeOfListing == "live"
                                             ? true
                                             : false
                                       }
                                    />
                                    <label
                                       className="form-check-label"
                                       htmlFor="live"
                                    >
                                       Live
                                    </label>
                                 </div>
                              </div>

                              {this.state.typeOfListing == "sandBox" ? (
                                 <div className="mt-2 row">
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label px-0 "
                                    >
                                       Public Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid col-12  "
                                       placeholder="Enter Public Key"
                                       name="publicKey"
                                       value={this.state.publicKey}
                                       onChange={(e) =>
                                          this.handleTypeOfListing(e)
                                       }
                                       onKeyPress={(e) =>
                                          this.onKeyPressAddGroup(e)
                                       }

                                       // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.publicKeyVal ? (
                                          <div className="col-11 ms-1 d-flex text-start myError">
                                             <br />
                                             <small className="fw-lighter text-start text-danger">
                                                Public key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label px-0  mt-2"
                                    >
                                       Secrete Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid  col-12 "
                                       placeholder="Enter Secrete Key"
                                       name="secreteKey"
                                       value={this.state.secreteKey}
                                       onChange={(e) =>
                                          this.handleTypeOfListing(e)
                                       }
                                       onKeyPress={(e) =>
                                          this.onKeyPressAddGroup(e)
                                       }

                                       // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.secreteKeyVal ? (
                                          <div className="col-11 ms-1 d-flex text-start myError">
                                             <br />
                                             <small className="fw-lighter text-start text-danger">
                                                Secrete key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                 </div>
                              ) : (
                                 <div className="mt-2 row">
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label px-0  "
                                    >
                                       Public Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid col-12  "
                                       placeholder="Enter Public Key"
                                       value={this.state.publicKeyLive}
                                       name="publicKeyLive"
                                       onChange={(e) =>
                                          this.handleTypeOfListing(e)
                                       }
                                       onKeyPress={(e) =>
                                          this.onKeyPressAddGroup(e)
                                       }

                                       // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.publicKeyLiveVal ? (
                                          <div className="col-11 ms-1 d-flex text-start myError">
                                             <br />
                                             <small className="fw-lighter text-start text-danger">
                                                Public key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label mt-2 px-0 "
                                    >
                                       Secrete Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid  col-12"
                                       placeholder="Enter Secrete Key"
                                       value={this.state.secreteKeyLive}
                                       name="secreteKeyLive"
                                       onChange={(e) =>
                                          this.handleTypeOfListing(e)
                                       }
                                       onKeyPress={(e) =>
                                          this.onKeyPressAddGroup(e)
                                       }

                                       // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.secreteKeyLiveVal ? (
                                          <div className="col-11 ms-1 d-flex text-start myError">
                                             <br />
                                             <small className="fw-lighter text-start text-danger">
                                                Secrete key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>
                     {this.state.typeOfListing == "sandBox" ? (
                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* ///////////////// */}

                           {this.state.publicKey != "" &&
                           this.state.publicKey != undefined &&
                           this.state.secreteKey != "" &&
                           this.state.secreteKey != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveModel}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveModel}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     ) : (
                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* ///////////////// */}

                           {this.state.publicKeyLive != "" &&
                           this.state.publicKeyLive != undefined &&
                           this.state.secreteKeyLive != "" &&
                           this.state.secreteKeyLive != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveLiveModel}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveLiveModel}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     )}
                  </div>
               </div>
            </div>
            {/* /////////////////edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Edit{" "}
                           {this.state.typeOfListing == "sandBox"
                              ? "Sandbox"
                              : "Live"}
                        </h5>
                        <button
                           type="button"
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body justify-content-center">
                        <div className="100 mx-2">
                           {/* <div className="d-flex justify-content-center">
										<div className="form-check">
											<input
												className="form-check-input"
												type="radio"
												name="flexRadioDefault"
												id="sandBox"
												onClick={(e) => this.WhichTypeOfListingChangeEdit(e)}
												defaultChecked={
													this.state.typeOfListing == "sandBox" ? true : false
												}
											/>
											<label className="form-check-label me-3" htmlFor="sandBox">
												Sand Box
											</label>
										</div>

										<div className="form-check">
											<input
												className="form-check-input"
												type="radio"
												name="flexRadioDefault"
												id="live"
												onClick={(e) => this.WhichTypeOfListingChangeEdit(e)}
												defaultChecked={this.state.typeOfListing == "live" ? true : false}
											/>
											<label className="form-check-label" htmlFor="live">
												Live
											</label>
										</div>
									</div> */}

                           {this.state.typeOfListing == "sandBox" ? (
                              <div className="mt-2 row">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label px-0 "
                                 >
                                    Public Key
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid col-12  "
                                    placeholder="Enter Public Key"
                                    name="publicKey"
                                    value={this.state.publicKey}
                                    onChange={(e) =>
                                       this.handleTypeOfListing(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }

                                    // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                 />
                                 <div className="d-flex col-12 ">
                                    {this.state.publicKeyVal ? (
                                       <div className="col-11 ms-1 d-flex text-start myError">
                                          <br />
                                          <small className="fw-lighter text-start text-danger">
                                             Public key is required
                                          </small>
                                       </div>
                                    ) : null}
                                 </div>
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label px-0  mt-2"
                                 >
                                    Secrete Key
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid  col-12 "
                                    placeholder="Enter Secrete Key"
                                    name="secreteKey"
                                    value={this.state.secreteKey}
                                    onChange={(e) =>
                                       this.handleTypeOfListing(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }

                                    // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                 />
                                 <div className="d-flex col-12 ">
                                    {this.state.secreteKeyVal ? (
                                       <div className="col-11 ms-1 d-flex text-start myError">
                                          <br />
                                          <small className="fw-lighter text-start text-danger">
                                             Secrete key is required
                                          </small>
                                       </div>
                                    ) : null}
                                 </div>
                              </div>
                           ) : (
                              <div className="mt-2 row">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label px-0  "
                                 >
                                    Public Key
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid col-12  "
                                    placeholder="Enter Public Key"
                                    value={this.state.publicKeyLive}
                                    name="publicKeyLive"
                                    onChange={(e) =>
                                       this.handleTypeOfListing(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }

                                    // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                 />
                                 <div className="d-flex col-12 ">
                                    {this.state.publicKeyLiveVal ? (
                                       <div className="col-11 ms-1 d-flex text-start myError">
                                          <br />
                                          <small className="fw-lighter text-start text-danger">
                                             Public key is required
                                          </small>
                                       </div>
                                    ) : null}
                                 </div>
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mt-2 px-0 "
                                 >
                                    Secrete Key
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid  col-12"
                                    placeholder="Enter Secrete Key"
                                    value={this.state.secreteKeyLive}
                                    name="secreteKeyLive"
                                    onChange={(e) =>
                                       this.handleTypeOfListing(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }

                                    // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                 />
                                 <div className="d-flex col-12 ">
                                    {this.state.secreteKeyLiveVal ? (
                                       <div className="col-11 ms-1 d-flex text-start myError">
                                          <br />
                                          <small className="fw-lighter text-start text-danger">
                                             Secrete key is required
                                          </small>
                                       </div>
                                    ) : null}
                                 </div>
                              </div>
                           )}
                        </div>
                        {this.state.modelNameVal ? (
                           <div className="modelValidation">Enter Color</div>
                        ) : null}
                     </div>
                     {this.state.typeOfListing == "sandBox" ? (
                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* ///////////////// */}

                           {this.state.publicKey != "" &&
                           this.state.publicKey != undefined &&
                           this.state.secreteKey != "" &&
                           this.state.secreteKey != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveModel}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Update
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveModel}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Update
                              </button>
                           )}
                        </div>
                     ) : (
                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* ///////////////// */}

                           {this.state.publicKeyLive != "" &&
                           this.state.publicKeyLive != undefined &&
                           this.state.secreteKeyLive != "" &&
                           this.state.secreteKeyLive != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveLiveModel}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Update
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveLiveModel}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Update
                              </button>
                           )}
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default StripeSetting;

import { BaseURL } from "../BaseURL";

export const getReasons = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/reports?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddReports = async (accessToken, slectetdReasonId, reasonTitle) => {
  const response = await fetch(`${BaseURL}/admin/reports`, {
    body: JSON.stringify({
      title: reasonTitle,
      report_type: slectetdReasonId,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeleteReasons = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/reports/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditReason = async (accessToken, slectetdReasonId, reasonTitle, id) => {
  const response = await fetch(`${BaseURL}/admin/reports/${id}`, {
    body: JSON.stringify({
      title: reasonTitle,
      report_type: slectetdReasonId,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getReportType = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/report-types`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

// ..//////////////////// reasons  Activ deactive
export const ActiveReasonsID = async (accessToken, vehicleModelID) => {
  const response = await fetch(`${BaseURL}/admin/reports/active/${vehicleModelID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeActiveReasonsID = async (accessToken, vehicleModelID) => {
  const response = await fetch(`${BaseURL}/admin/reports/deactive/${vehicleModelID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

import { BaseURL } from "../BaseURL";
export const getBillingDetail = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/billing-information?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",

      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
      accept: "application/json",
    },
  });
  const result = await response.json();
  return result;
};

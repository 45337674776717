import { BaseURL } from "../BaseURL";
export const getAllAdminProfiles = async (accessToken) => {
   const response = await fetch(`${BaseURL}/admin/view-profile`, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
         "Access-Control-Allow-Origin": "*",
      },
   });
   const result = await response.json();

   return result;
};
export const EditAdminProfiles = async (
   accessToken,
   firstName,
   lastName,
   email,
   phone,
   editProfileImage
) => {
   const form_data = new FormData();
   form_data.append("first_name", firstName);
   form_data.append("last_name", lastName);
   form_data.append("email", email);
   form_data.append("phone", phone);
   form_data.append("profile_image", editProfileImage);
   const response = await fetch(`${BaseURL}/admin/update-profile`, {
      body: form_data,
      method: "POST",
      headers: {
         // "Content-Type": "application/json",
         Accept: "application/json",
         Authorization: "Bearer" + " " + accessToken,
         // "Access-Control-Allow-Origin": "*",
      },
   });
   const result = await response.json();
   return result;
};

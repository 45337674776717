import { BaseURL } from "../BaseURL";

export const getVehicleModel = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/models?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddModel = async (accessToken, modelName) => {
  console.log("index", modelName);
  const response = await fetch(`${BaseURL}/admin/models`, {
    body: JSON.stringify({
      name: modelName,
    }),
    method: "POST",
    headers: {
      // Accept: "application/json",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const deleteVehicleModel = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/models/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditModel = async (accessToken, modelName, id) => {
  const response = await fetch(`${BaseURL}/admin/models/${id}`, {
    body: JSON.stringify({
      name: modelName,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const SearchModel = async (accessToken, searchWord) => {
  const response = await fetch(`${BaseURL}/admin/models/search`, {
    body: JSON.stringify({
      search: searchWord,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
// ..//////////////////// vhicle model  Activ deactive
export const ActiveVehicleModelsID = async (accessToken, vehicleModelID) => {
  const response = await fetch(`${BaseURL}/admin/models/active/${vehicleModelID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeActiveVehicleModelsID = async (accessToken, vehicleModelID) => {
  const response = await fetch(`${BaseURL}/admin/models/deactive/${vehicleModelID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

import { BaseURL } from "../BaseURL";

export const getVehicleColors = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/colors?page=${page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddColor = async (accessToken, name, color) => {
  const response = await fetch(`${BaseURL}/admin/colors`, {
    body: JSON.stringify({
      name: name,
      color: color,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditColor = async (accessToken, colorName, colorCode, id) => {
  const response = await fetch(`${BaseURL}/admin/colors/${id}`, {
    body: JSON.stringify({
      color: colorCode,
      name: colorName,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const deleteColor = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/colors/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const SearchColor = async (accessToken, page, color) => {
  const response = await fetch(`${BaseURL}/admin/colors/search?page=${page}`, {
    method: "POST",
    body: JSON.stringify({
      search: color,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
//////////////////////vehicle color/////
export const ActiveVehicleColorsID = async (accessToken, vehicleID) => {
  const response = await fetch(`${BaseURL}/admin/colors/active/${vehicleID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeActiveVehicleColorsID = async (accessToken, vehicleID) => {
  const response = await fetch(`${BaseURL}/admin/colors/deactive/${vehicleID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

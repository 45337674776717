import { BaseURL } from "../BaseURL";
export const getInvoices = async (accessToken, page = "") => {
   let endpoint = `${BaseURL}/admin/all-invoice`;
   if (page !== "") {
      endpoint = `${BaseURL}/admin/all-invoice/${page}`;
   }
   const response = await fetch(endpoint, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",

         Authorization: "Bearer" + "  " + accessToken,
         "Access-Control-Allow-Origin": "*",
         accept: "application/json",
      },
   });
   const result = await response.json();
   return result;
};

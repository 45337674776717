import React, { Component, useState } from "react";
import { PhotoshopPicker } from "react-color";
import { Link } from "react-router-dom";
import "./QuickNotes.css";
import {
   GetQuickNotes,
   AddQuickNotes,
   EditQuickNotes,
   ActiveQuickNotesID,
   DeActiveQuickNotesID,
   DeleteQuickNotes,
} from "../Services/QuickNotes/index";
import pencil from "../../assets/icons/Pencil-01.svg";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { imageOverlay } from "leaflet";
import image_userA from "../../assets/QuickNotesIcon/QuickNotesA.svg";
import image_userD from "../../assets/QuickNotesIcon/QuickNotesDA.svg";
import dummyCar from "../../assets/vehicleIcon/dummyCar.jpg";
import Pagination from "../VerticalLayout/pagination/Pagination";

class QuickNotes extends Component {
   constructor(props) {
      super(props);

      this.handleChange = this.handleChange.bind(this);
      this.SaveQuickNotes = this.SaveQuickNotes.bind(this);
      this.updateQuickNotes = this.updateQuickNotes.bind(this);

      this.state = {
         vehicleNameVal: false,

         addProfile: "",
         showEditProfileImage: "",
         editProfileImage: "",
         showProfileImage: "",
         notesDiscriptionVal: false,
         notesDiscription: "",

         imageIcon: "",
         showProfileImageVal: false,

         idQuickNotes: "",

         statusValue: "",
         statusQuickNotes: "",
         statusValueVal: false,

         quickNotesArray: [],

         currentPage: "",
         lastPage: "",
         loading: false,
         pageIDs: [],

         loadingModel: true,
      };
   }
   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getAllQuickNotes();
   }

   async getAllQuickNotes(page) {
      try {
         let IDArray = [];

         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const response = await GetQuickNotes(accessToken, page);

         if (response.status === true) {
            for (let i = response.notes.from; i <= response.notes.to; i++) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.setState({ loading: true });

            this.setState({ quickNotesArray: response.notes.data });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async SaveQuickNotes() {
      this.setState({ loadingModel: false });
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      if (
         this.state.notesDiscription == "" ||
         this.state.notesDiscription == undefined
      ) {
         this.setState({ notesDiscriptionVal: true });
      }
      if (this.state.addProfile == "" || this.state.addProfile == undefined) {
         this.setState({ showProfileImageVal: true });
      }
      if (
         this.state.notesDiscription !== "" &&
         this.state.notesDiscription !== undefined &&
         this.state.addProfile !== "" &&
         this.state.addProfile !== undefined
      ) {
         try {
            const response = await AddQuickNotes(
               accessToken,
               this.state.notesDiscription,
               this.state.addProfile
            );

            if (response.status == true) {
               this.getAllQuickNotes(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Short notes added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  notesDiscription: "",
                  addProfile: "",
                  editvehicleID: "",
               });
            } else {
               this.setState({ loadingModel: false });
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: "Short notes has already been taken.",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };

   handleChange = (e, inputField) => {
      // Add Logo Error
      if (
         e.target?.files[0]?.type == "image/svg+xml" ||
         e.target?.files[0]?.type == "image/svg"
      ) {
         if (inputField == "logo") {
            this.setState({ addProfile: e.target.files[0] });
            if (e.target.files.length !== 0) {
               this.setState({
                  showProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            }
         }
         if (inputField == "Elogo") {
            this.setState({ editProfileImage: e.target.files[0] });
            if (e.target.files.length !== 0) {
               this.setState({
                  showEditProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            }
         }
      } else {
         Swal.fire({
            position: "top",
            icon: "warning",
            title: "Please upload svg file",
            showConfirmButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
         });
      }
   };

   initialValue = (id, description, status, imageBG) => {
      this.setState({
         idQuickNotes: id,
         notesDiscription: description,
         showEditProfileImage: imageBG,
         statusQuickNotes: status,
      });
   };
   async updateQuickNotes() {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      if (
         this.state.notesDiscription == "" ||
         this.state.notesDiscription == undefined
      ) {
         this.setState({ notesDiscriptionVal: true });
      }
      if (
         this.state.notesDiscription !== "" &&
         this.state.notesDiscription !== undefined
      ) {
         try {
            const response = await EditQuickNotes(
               accessToken,
               this.state.notesDiscription,
               this.state.editProfileImage,
               this.state.statusQuickNotes,
               this.state.idQuickNotes
            );
            if (response.status == true) {
               // this.getAllQuickNotes();
               this.getAllQuickNotes(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Short notes updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleName: "",
                  status: "",
                  editvehicleID: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   paginate = (pageNumber) => {
      this.getAllVehicles(pageNumber);
      window.scrollTo({
         top: 0,
         // left: 100,
         behavior: "smooth",
      });
      // window.scrollTo(0, 1000)
   };
   ClearAllField = () => {
      this.getAllQuickNotes();
      this.setState({
         notesDiscription: "",
         showProfileImage: dummyCar,
         addProfile: "",
         showProfileImageVal: false,
         imageIcon: "",
         notesDiscriptionVal: false,
      });
   };

   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   async ActiveQuickNotesID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to activate this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await ActiveQuickNotesID(accessToken, id);
            if (result.status === true) {
               this.getAllQuickNotes(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Quick notes active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveQuickNotesID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to Deactive this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeActiveQuickNotesID(accessToken, id);

            if (result.status === true) {
               this.getAllQuickNotes(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Quick notes Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async DeleteQuickNotesID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeleteQuickNotes(accessToken, id);
            if (result.status === true) {
               this.getAllQuickNotes(this.state.currentPage);
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Quick note removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllQuickNotes(page);
   };

   render() {
      return (
         <>
            <div className="page-content">
               <Container fluid>
                  <h5 className="fs-5 mb-3 fw-bold">Quick Notes</h5>
                  {this.state.loading ? (
                     this.state.quickNotesArray.length > 0 ? (
                        <Card className="p-4 ">
                           <div className="d-flex justify-content-between">
                              <div></div>
                              <div>
                                 <Button
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropOne"
                                    type="button"
                                    onClick={this.ClearAllField}
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn-rounded mb-2 me-2"
                                 >
                                    <span>Add Note</span>
                                 </Button>
                              </div>
                           </div>
                           <div className="overflow-auto">
                              <table class="table  table-responsive">
                                 <thead>
                                    <tr>
                                       <th scope="col">No.</th>
                                       <th scope="col">Icon</th>
                                       <th scope="col">Description</th>
                                       <th scope="col">Status</th>
                                       <th scope="col">Created Date</th>

                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {this.state.quickNotesArray?.length > 0 ? (
                                       <>
                                          {" "}
                                          {this.state.quickNotesArray.map(
                                             (item, outer) => {
                                                return (
                                                   <>
                                                      {/* backgroundImage: `url(${slot.deal?.deal_image[0].folder_path}${slot.deal?.deal_image[0].file_name}`, */}

                                                      <tr key={outer}>
                                                         {this.state.pageIDs?.map(
                                                            (item, inner) =>
                                                               outer ==
                                                               inner ? (
                                                                  <td>
                                                                     {item.id}
                                                                  </td>
                                                               ) : null
                                                         )}
                                                         <td>
                                                            {item?.note_image
                                                               ?.folder_path +
                                                            item?.note_image
                                                               ?.file_name ? (
                                                               <img
                                                                  className="img-fluid iconImage me-3"
                                                                  style={{
                                                                     background:
                                                                        "linear-gradient(#3E94A6, #323F4B)",
                                                                  }}
                                                                  src={
                                                                     item
                                                                        ?.note_image
                                                                        ?.folder_path +
                                                                     item
                                                                        ?.note_image
                                                                        ?.file_name
                                                                  }
                                                                  alt=""
                                                               />
                                                            ) : (
                                                               <img
                                                                  className="img-fluid iconImage me-3"
                                                                  style={{
                                                                     background:
                                                                        "linear-gradient(#3E94A6, #323F4B)",
                                                                  }}
                                                                  src={dummyCar}
                                                                  alt=""
                                                                  width={"20px"}
                                                                  height={
                                                                     "20px"
                                                                  }
                                                               />
                                                            )}
                                                         </td>

                                                         <td>
                                                            {item.description}{" "}
                                                         </td>
                                                         <td>
                                                            {item.status ==
                                                            "active" ? (
                                                               <span
                                                                  className="badge bg-success "
                                                                  style={{
                                                                     paddingLeft: 13,
                                                                     paddingRight: 13,
                                                                  }}
                                                               >
                                                                  Active
                                                               </span>
                                                            ) : item.status ==
                                                              "deactive" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Deactive
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            {this.getDate(
                                                               item.created_at
                                                            )}
                                                         </td>
                                                         <td>
                                                            <Link
                                                               to="#"
                                                               className="me-3 text-primary"
                                                            >
                                                               <i
                                                                  title="Edit"
                                                                  className="mdi mdi-pencil font-size-18"
                                                                  data-bs-toggle="modal"
                                                                  data-bs-target="#staticBackdropTwo"
                                                                  onClick={() => {
                                                                     this.setState(
                                                                        {
                                                                           notesDiscriptionVal: false,
                                                                        }
                                                                     );

                                                                     this.initialValue(
                                                                        item.id,
                                                                        item.description,
                                                                        item.status,
                                                                        // sssss?
                                                                        `${item?.note_image?.folder_path}${item?.note_image?.file_name}`
                                                                     );
                                                                  }}
                                                               ></i>
                                                            </Link>
                                                            <Link
                                                               to="#"
                                                               className="text-danger"
                                                            >
                                                               <i
                                                                  title="Delete"
                                                                  className="mdi mdi-trash-can font-size-18 me-3"
                                                                  onClick={() =>
                                                                     this.DeleteQuickNotesID(
                                                                        item.id
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            {/* //////////////////////////////////////////// */}
                                                            {item?.status ==
                                                            "active" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Deactive"
                                                               >
                                                                  <img
                                                                     src={
                                                                        image_userD
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     height={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.DeActiveQuickNotesID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : item?.status ==
                                                              "deactive" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Active"
                                                               >
                                                                  <img
                                                                     src={
                                                                        image_userA
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     height={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.ActiveQuickNotesID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : (
                                                               ""
                                                            )}
                                                         </td>
                                                      </tr>
                                                   </>
                                                );
                                             }
                                          )}
                                       </>
                                    ) : (
                                       <>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>

                                          <tr>
                                             <td className="pt-5">
                                                <div class="record-not-found pt-5">
                                                   Record Not Found
                                                </div>
                                             </td>
                                          </tr>
                                       </>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>
                     ) : (
                        <>
                           <div className="  text-end">
                              <Button
                                 data-bs-toggle="modal"
                                 data-bs-target="#staticBackdropOne"
                                 type="button"
                                 onClick={this.ClearAllField}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn-rounded mb-2 me-2"
                              >
                                 <span>Add Note</span>
                              </Button>
                           </div>

                           <div className="bg-white p-5">
                              <h1 className="mt-5 text-center">
                                 {" "}
                                 Sorry! Record Not Found
                              </h1>
                           </div>
                        </>
                     )
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center text-bold"
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
               {/* //////////////edit model */}
               <div
                  className="modal fade"
                  id="staticBackdropTwo"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Edit Note
                           </h5>
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>

                        <div className="mt-4  mt-2 row">
                           <div className="m-auto col-4">
                              <div
                                 className=" image-input-outline"
                                 data-kt-image-input="true"
                                 style={{
                                    width: "125px",
                                    height: "125px",
                                 }}
                              >
                                 <div
                                    className="image-input-wrapper d-flex w-125px h-125px"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                       width: "125px",
                                       height: "125px",
                                    }}
                                 >
                                    <img
                                       src={this.state.showEditProfileImage}
                                       className="img-fluid modal_image"
                                       alt=""
                                       style={{
                                          maxHeight: "125px",
                                          maxWidth: "125px",
                                       }}
                                    />
                                 </div>
                              </div>
                              <label
                                 for="avatar"
                                 style={{
                                    overflow: "hidden",
                                    cursor: "pointer",
                                    marginBottom: "10px",
                                 }}
                                 data-kt-image-input-action="change"
                                 data-bs-toggle="tooltip"
                                 title="Edit"
                              >
                                 <i
                                    title="Edit"
                                    style={{
                                       position: "absolute",
                                       left: "128px",
                                       top: "-19px",
                                       zIndex: 11,
                                    }}
                                 >
                                    <img
                                       src={pencil}
                                       width={30}
                                       height={25}
                                       alt=""
                                       className="d-inline cursor-pointer"
                                    />
                                 </i>
                                 <input
                                    type="file"
                                    name="avatar"
                                    accept=".svg"
                                    onChange={(e) =>
                                       this.handleChange(e, "Elogo")
                                    }
                                    style={{
                                       opacity: 0,
                                       position: "absolute",
                                       left: "125px",
                                       top: "-48px",
                                       zIndex: 111,
                                       cursor: "pointer",
                                       width: "40px",
                                       height: "55px",
                                    }}
                                 />
                                 <input type="hidden" />
                              </label>
                           </div>
                        </div>

                        <div className="mx-2">
                           <label
                              htmlFor="exampleFormControlInput1 "
                              className="fw-bolder required form-label mb-0"
                           >
                              Description
                           </label>
                           <br />
                           <textarea
                              value={this.state.notesDiscription}
                              className="form-control"
                              onChange={(e) =>
                                 this.setState({
                                    notesDiscription: e.target.value,
                                    notesDiscriptionVal: false,
                                 })
                              }
                              onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                              name=""
                              id=""
                           ></textarea>
                           <div className="d-flex col-12 ">
                              {this.state.notesDiscriptionVal ? (
                                 <div className="modelValidation ">
                                    Enter description{" "}
                                 </div>
                              ) : null}
                           </div>
                        </div>

                        <div className="modal-footer  text-nowrap">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary "
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.notesDiscription != "" &&
                           this.state.notesDiscription != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 data-bs-dismiss="modal"
                                 onClick={() => this.updateQuickNotes()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Update
                              </button>
                           ) : (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={() => this.updateQuickNotes()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Update
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* /////////////////////Add model */}
               <div
                  className="modal fade"
                  id="staticBackdropOne"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Add Note
                           </h5>
                           <button
                              type="button"
                              //   onClick={this.AllClearFields}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>

                        <div>
                           <div className="mt-4  row">
                              <div className="m-auto col-4">
                                 <div
                                    className=" image-input-outline"
                                    data-kt-image-input="true"
                                    style={{
                                       width: "125px",
                                       height: "125px",
                                    }}
                                 >
                                    <div
                                       className="image-input-wrapper  d-flex"
                                       style={{
                                          background:
                                             "linear-gradient(#3E94A6, #323F4B)",
                                          width: "125px",
                                          height: "125px",
                                       }}
                                    >
                                       <img
                                          src={this.state.showProfileImage}
                                          className="img-fluid modal_image "
                                          alt=""
                                          style={{
                                             maxWidth: "125px",
                                             maxHeight: "125px",
                                          }}
                                       />
                                       <br />
                                    </div>
                                    {this.state.showProfileImageVal ? (
                                       <div className="modelValidation ">
                                          Icon is required{" "}
                                       </div>
                                    ) : null}
                                 </div>
                                 <label
                                    for="avatar"
                                    style={{
                                       overflow: "hidden",
                                       cursor: "pointer",
                                    }}
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change avatar"
                                 >
                                    <i
                                       title="Edit"
                                       style={{
                                          position: "absolute",
                                          left: "128px",
                                          top: "-19px",
                                          zIndex: 11,
                                       }}
                                    >
                                       <img
                                          src={pencil}
                                          width={30}
                                          height={25}
                                          alt=""
                                          className="d-inline cursor-pointer"
                                       />
                                    </i>
                                    <input
                                       type="file"
                                       name="avatar"
                                       accept=".svg"
                                       onChange={(e) =>
                                          this.handleChange(e, "logo")
                                       }
                                       onClick={(e) => {
                                          e.target.value = null;
                                       }}
                                       style={{
                                          opacity: 0,
                                          position: "absolute",
                                          left: "125px",
                                          top: "-48px",
                                          zIndex: 111,
                                          cursor: "pointer",
                                          width: "40px",
                                          height: "55px",
                                       }}
                                    />
                                    <input type="hidden" />
                                 </label>
                              </div>
                           </div>
                           <div className="pb-3 row mx-1">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0 "
                              >
                                 Description
                              </label>
                              <div className="col-12 ">
                                 <textarea
                                    value={this.state.notesDiscription}
                                    className="form-control"
                                    onChange={(e) =>
                                       this.setState({
                                          notesDiscription: e.target.value,
                                          notesDiscriptionVal: false,
                                       })
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }
                                    name=""
                                    id=""
                                 />
                                 {this.state.notesDiscriptionVal ? (
                                    <div className="modelValidation ">
                                       Enter description{" "}
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                        </div>
                        <div className="modal-footer text-nowrap">
                           <button
                              type="button"
                              //   onClick={this.AllClearFields}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {this.state.notesDiscription != "" &&
                           this.state.notesDiscription != undefined &&
                           this.state.addProfile != "" &&
                           this.state.addProfile != undefined ? (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveQuickNotes}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Note
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveQuickNotes}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Note
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default QuickNotes;

import { BaseURL } from "../BaseURL";

// export const AddCatagories = async (accessToken, name) => {
//   const response = await fetch(`${BaseURL}/admin/parking-vehicle-categories`, {
//     body: JSON.stringify({
//       name: name,
//     }),
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: "Bearer" + "  " + accessToken,
//       "Access-Control-Allow-Origin": "*",
//     },
//   });
//   const result = await response.json();

//   return result;
// };

export const getVehicleParking = async (
   accessToken,
   page,
   searchItem,
   minPrice,
   maxPrice
) => {
   const response = await fetch(`${BaseURL}/admin/parking?page=${page}`, {
      method: "POST",
      body: JSON.stringify({
         search: searchItem,
         min_price: minPrice,
         max_price: maxPrice,
      }),
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
         "Access-Control-Allow-Origin": "*",
      },
   });
   const result = await response.json();
   console.log("indexxxx", result);
   return result;
};
export const searchParking = async (
   accessToken,
   page,
   searchItem,
   minPrice,
   maxPrice
) => {
   const response = await fetch(
      `${BaseURL}/admin/parking/parking-search?page=${page}`,
      {
         method: "POST",
         body: JSON.stringify({
            search: searchItem,
            min_price: minPrice,
            max_price: maxPrice,
         }),
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};
export const setActiveParking = async (accessToken, parkingID) => {
   const response = await fetch(
      `${BaseURL}/admin/parking/active/${parkingID}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};
export const setDeActiveParking = async (accessToken, parkingID) => {
   const response = await fetch(
      `${BaseURL}/admin/parking/deactive/${parkingID}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};
export const setActiveParkingReport = async (accessToken, parkingID) => {
   const response = await fetch(
      `${BaseURL}/admin/parking-report/active/${parkingID}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};
export const setDeActiveParkingReport = async (accessToken, parkingID) => {
   const response = await fetch(
      `${BaseURL}/admin/parking-report/deactive/${parkingID}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};
export const getParkingProfileData = async (accessToken, parkingID) => {
   const response = await fetch(
      `${BaseURL}/admin/single-parking/${parkingID}`,
      {
         method: "GET",
         headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};

export const getReportedParking = async (accessToken, page) => {
   const response = await fetch(
      `${BaseURL}/admin/parking-report?page=${page}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
         },
      }
   );
   const result = await response.json();
   return result;
};

import { BaseURL } from "../../BaseURL";

export const AddUser = async (accessToken, firstName, lastName, email, phone, gender, status, countryID) => {
  const response = await fetch(`${BaseURL}/admin/users`, {
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      gender: gender,
      status: status,
      country: countryID,
    }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getCountry = async (accessToken) => {
  const response = await fetch(`${BaseURL}/countries`, {
    method: "GET",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const GetUser = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/users?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditUser = async (accessToken, firstName, lastName, email, phone, gender, status, countryID, id) => {
  const response = await fetch(`${BaseURL}/admin/users/${id}`, {
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      gender: gender,
      status: status,
      country: countryID,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeleteUser = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/users-delete/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const setActiveUser = async (accessToken, userID) => {
  const response = await fetch(`${BaseURL}/admin/active-user/${userID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const setDeActiveUser = async (accessToken, userID) => {
  const response = await fetch(`${BaseURL}/admin/deactive-user/${userID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const searchUser = async (accessToken, page, user) => {
  const response = await fetch(`${BaseURL}/admin/search-users?page=${page}`, {
    method: "POST",
    body: JSON.stringify({
      user: user,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

///////////////reported Users
export const setRejectUser = async (accessToken, userID) => {
  const response = await fetch(`${BaseURL}/admin/reject/${userID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const setAcceptUser = async (accessToken, userID) => {
  const response = await fetch(`${BaseURL}/admin/accept/${userID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  });
  const result = await response.json();

  return result;
};
export const getReportedUsers = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/reported-users?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getProfile = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/users/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

import { BaseURL } from "../BaseURL";

export const getPlate = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/plates?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddPlate = async (accessToken, ownerIdNumber, plateNumber, description, modelIdNumber, vehicleIdNumber, colorId) => {
  console.log("index,", colorId);

  const response = await fetch(`${BaseURL}/admin/plates`, {
    body: JSON.stringify({
      number_plate: plateNumber,
      vehicle: vehicleIdNumber,
      model: modelIdNumber,
      color: colorId,
      discription: description,

      user: ownerIdNumber,
    }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditPlates = async (accessToken, plateNameNew, plateNumber, description, modelNameNew, vehicleNameNew, colorId, id) => {
  const response = await fetch(`${BaseURL}/admin/plates/${id}`, {
    body: JSON.stringify({
      number_plate: plateNumber,
      vehicle: vehicleNameNew,
      model: modelNameNew,
      color: colorId,
      discription: description,
      user: plateNameNew,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeletePlates = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/delete-plate/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getPlateModel = async (accessToken) => {
  const response = await fetch(`${BaseURL}/admin/admin-models`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getPlateVehicle = async (accessToken) => {
  const response = await fetch(`${BaseURL}/admin/admin-vehicles`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();

  return result;
};
export const getPlateOwnerName = async (accessToken) => {
  const response = await fetch(`${BaseURL}/admin/admin-users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();

  return result;
};
export const searchPlate = async (accessToken, plateNumber, page) => {
  const response = await fetch(`${BaseURL}/admin/plate-search?page=${page}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      number_plate: plateNumber,
    }),
  });
  const result = await response.json();
  return result;
};
export const getPlateColor = async (accessToken) => {
  const response = await fetch(`${BaseURL}/admin/admin-colors`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const setDeActivePlate = async (accessToken, userID) => {
  const response = await fetch(`${BaseURL}/admin/deactive-plate/${userID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const setActivePlate = async (accessToken, userID) => {
  const response = await fetch(`${BaseURL}/admin/active-plate/${userID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

///////////////reported Plates
export const getReportedPlates = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/reported-plates?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

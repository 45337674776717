import React from "react";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            {/* {new Date().getFullYear()} */}
            <Col sm={6}>Copyright 2022 all rights reserved</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {/* Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign  */}
                Powered By Webicosoft
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

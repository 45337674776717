import React, { Component } from "react";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../Common/Breadcrumb";
import KanbanBoard from "./KanbanBoard";

//Import Logo
import avatar2 from "../../assets/images/users/avatar-2.jpg";

import avatar4 from "../../assets/images/users/avatar-4.jpg";

//Import scss
import "./kanban-style.scss";
import { getDeals } from "../Services/AllGroups/index";

class IndexKanban extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // columns:[],
      columns: [
        {
          id: 1,
          title: "Deal Slots",
          columnsubtitle: "Total Deals",
          cards: [
            {
              // id: new Date().getTime()*100,
              content: {
                id: "",
                title: "",
                subtitle: "",
                date: "2020",
                progressValue: 75,
                team: [
                  { id: 1, name: "joseph", img: avatar2 },
                  { id: 2, name: "joseph", img: avatar4 },
                ],
              },
            },
          ],
        },
      ],
    };
  }

  async componentDidMount() {
    // Getting Models from API Call----------------------
    this.getAllDeals();
  }

  async getAllDeals(page) {
    // let vehiclesArray = [];

    try {
      let arrayEle = [];
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      const dealsAPI = await getDeals(accessToken, page);

      if (dealsAPI.status === true) {
        this.setState({ dealsArray: dealsAPI.deals.data });
        this.state.dealsArray.map((item, index) => {
          arrayEle.push({
            id: 1,
            title: "Deal Slots",
            columnsubtitle: "Total Deals",
            cards: [
              {
                id: new Date().getTime(),
                content: {
                  id: item.id,
                  title: item.title,
                  subtitle: item.description,
                  date: item.updated_at,
                  progressValue: 75,
                  team: [
                    { id: 1, name: "joseph", img: avatar2 },
                    { id: 2, name: "joseph", img: avatar4 },
                  ],
                },
              },
            ],
          });
          this.setState({ columns: arrayEle });
        });
      }
    } catch (err) {
      console.log("error", err);
    }
  }
  render() {
    const breadcrumbItems = [
      { title: "Tables", link: "#" },
      { title: "Deals", link: "#" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="col-6 pb-2">
              <Breadcrumbs title="Deals" breadcrumbItems={breadcrumbItems} />
            </div>

            <Row>
              <KanbanBoard board={this.state} content={this.state.columns} />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default IndexKanban;

import { BaseURL } from "../BaseURL";

export const getDeals = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/deals`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getGroups = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/groups?page=${page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddGroups = async (
  accessToken,
  slotFirstName,
  slotFirstDescription,
  slotFirstPricePerDay,
  slotFirstPricePerWeek,
  slotFirstPricePerMonth,
  slotFirstPricePerYear,
  slotSecondName,
  slotSecondDescription,
  slotSecondPricePerDay,
  slotSecondPricePerWeek,
  slotSecondPricePerMonth,
  slotSecondPricePerYear,
  slotThirdName,
  slotThirdDescription,
  slotThirdPricePerDay,
  slotThirdPricePerWeek,
  slotThirdPricePerMonth,
  slotThirdPricePerYear,
  slotForthName,
  slotForthDescription,
  slotForthPricePerDay,
  slotForthPricePerWeek,
  slotForthPricePerMonth,
  slotForthPricePerYear,

  firstDaySwitch,
  secondDaySwitch,
  thirdDaySwitch,
  forthDaySwitch,

  firstMonthSwitch,
  secondMonthSwitch,
  thirdMonthSwitch,
  forthMonthSwitch,

  firstWeekSwitch,
  secondWeekSwitch,
  thirdWeekSwitch,
  forthWeekSwitch,

  firstYearSwitch,
  secondYearSwitch,
  thirdYearSwitch,
  forthYearSwitch
) => {
  const response = await fetch(`${BaseURL}/admin/groups`, {
    body: JSON.stringify({
      slot_1_name: slotFirstName,
      description1: slotFirstDescription,
      slot_1_per_day_price: slotFirstPricePerDay,
      slot_1_per_week_price: slotFirstPricePerWeek,
      slot_1_per_month_price: slotFirstPricePerMonth,
      slot_1_per_year_price: slotFirstPricePerYear,

      slot_2_name: slotSecondName,
      description2: slotSecondDescription,
      slot_2_per_day_price: slotSecondPricePerDay,
      slot_2_per_week_price: slotSecondPricePerWeek,
      slot_2_per_month_price: slotSecondPricePerMonth,
      slot_2_per_year_price: slotSecondPricePerYear,

      slot_3_name: slotThirdName,
      description3: slotThirdDescription,
      slot_3_per_day_price: slotThirdPricePerDay,
      slot_3_per_week_price: slotThirdPricePerWeek,
      slot_3_per_month_price: slotThirdPricePerMonth,
      slot_3_per_year_price: slotThirdPricePerYear,

      slot_4_name: slotForthName,
      description4: slotForthDescription,
      slot_4_per_day_price: slotForthPricePerDay,
      slot_4_per_week_price: slotForthPricePerWeek,
      slot_4_per_month_price: slotForthPricePerMonth,
      slot_4_per_year_price: slotForthPricePerYear,

      slot_1_per_day_status: firstDaySwitch,
      slot_2_per_day_status: secondDaySwitch,
      slot_3_per_day_status: thirdDaySwitch,
      slot_4_per_day_status: forthDaySwitch,

      slot_1_per_month_status: firstMonthSwitch,
      slot_2_per_month_status: secondMonthSwitch,
      slot_3_per_month_status: thirdMonthSwitch,
      slot_4_per_month_status: forthMonthSwitch,

      slot_1_per_week_status: firstWeekSwitch,
      slot_2_per_week_status: secondWeekSwitch,
      slot_3_per_week_status: thirdWeekSwitch,
      slot_4_per_week_status: forthWeekSwitch,

      slot_1_per_year_status: firstYearSwitch,
      slot_2_per_year_status: secondYearSwitch,
      slot_3_per_year_status: thirdYearSwitch,
      slot_4_per_year_status: forthYearSwitch,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  });
  const result = await response.json();
  return result;
};
export const DeleteGroup = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/groups/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  console.log("iiiiiiiii", result);
  return result;
};
export const EditGroup = async (accessToken, price, id) => {
  const response = await fetch(`${BaseURL}/admin/update-slot-price/${id}`, {
    body: JSON.stringify({
      price: price,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getSubmitFirstDeal = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/approve-deals/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const getSubmitFirstDealDisApproved = async (accessToken, id, reason) => {
  {
  }
  const response = await fetch(`${BaseURL}/admin/dis-approve-deals/${id}`, {
    body: JSON.stringify({
      reason: reason,
      // report_type: slectetdReasonId,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddTablesData = async (
  accessToken,
  tablePriceDay,
  daySwitch,
  tablePriceWeek,
  weekSwitch,
  tablePriceMonth,
  monthSwitch,
  tablePriceYear,
  yearSwitch,
  slot,
  description,
  name
) => {
  const response = await fetch(`${BaseURL}/admin/groups/${slot}`, {
    body: JSON.stringify({
      slot_id: slot,
      name: name,
      amount_per_day: tablePriceDay,
      duration_per_day: "per_day",
      amount_per_week: tablePriceWeek,
      duration_per_week: "per_week",
      amount_per_month: tablePriceMonth,
      duration_per_month: "per_month",
      amount_per_year: tablePriceYear,
      duration_per_year: "per_year",
      status_per_day: daySwitch,
      status_per_week: weekSwitch,
      status_per_month: monthSwitch,
      status_per_year: yearSwitch,
      description: description,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  });
  const result = await response.json();
  return result;
};

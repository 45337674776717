import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getVehicleModel, AddModel, deleteVehicleModel, EditModel, SearchModel } from "../../Services/UserServices/Index";
// import franchiseIcon from '../../assets/icons/Branch-icon-06.svg'
// import franchiseIconBlue from '../../assets/icons/Branch-icon-blue.svg'
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Swal from "sweetalert2";
import StripeSetting from "./StripeSetting";
import PaymentMethod from "./PaymentMethod";
class ManagePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeOfListing: "stripeSetting",
    };
  }
  WhichTypeOfListingChange = async (e) => {
    if (e.target.name == "stripeSetting") {
      this.setState({
        typeOfListing: "stripeSetting",
      });
    } else if (e.target.name == "paymentMethod") {
      this.setState({
        typeOfListing: "paymentMethod",
      });
    }
  };

  render() {
    return (
      <div className="page-content">
        <h5 className="fs-5 mb-3 fw-bold">Manage Payment Methods </h5>
        <Card className="p-4 ">
          <div className="d-flex justify-content-between">
            <div>
              {/* <div className="search-box "> */}
              {/* <input
												type="text"
												class="form-control rounded-pill"
												placeholder="Search"
												aria-label="Search"
												aria-describedby="search-addon"
												onChange={(e) => this.SearchChangeHandler(e)}
											/> */}
              {/* </div> */}
            </div>
            {/* <div>
              <a
                className={`  cursor-pointer p-0 me-3 ${this.state.typeOfListing == "stripeSetting" ? "text-primary" : "text-dark"} `}
                role="button"
                // className="cursor-pointer me-4"
                name="stripeSetting"
                onClick={(e) => this.WhichTypeOfListingChange(e)}
              >
                Stripe Setting
              </a>
              <a
                className={` cursor-pointer p-0   ${this.state.typeOfListing == "stripeSetting" ? "text-dark" : "text-primary"}  `}
                role="button"
                name="paymentMethod"
                onClick={(e) => this.WhichTypeOfListingChange(e)}
              >
                Payment Method
              </a>
            </div> */}
          </div>

          {/* <div>
            {this.state.typeOfListing == "stripeSetting" ? (
              <>
                <StripeSetting />
              </>
            ) : this.state.typeOfListing == "paymentMethod" ? (
              <PaymentMethod />
            ) : null}
          </div> */}
          <PaymentMethod />
        </Card>
      </div>
    );
  }
}

export default ManagePayment;

import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Container } from "reactstrap";
import logodark from "../../assets/images/logo-dark.png";
import { useDispatch, useSelector } from "react-redux";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { AddGroups } from "../Services/AllGroups/index";
import Swal from "sweetalert2";

//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';

class AddDivButton extends Component {
  constructor(props) {
    super(props);
    this.AddGroup = this.AddGroup.bind(props);
    this.state = {
      breadcrumbItems: [
        { title: "UI Elements", link: "#" },
        { title: "SweetAlert 2", link: "#" },
      ],
      basic: false,
      with_title: false,
      success_msg: false,
      confirm_msg: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      confirm_both: false,
      img_header: false,
      img_html: false,
      custom_div: false,
      sweet_timer: false,
      custom_div1: false,
      emailtxt: "",
      emailerr: "",
      step1: false,
      step1_txt: "",
      step2: false,
      step2_txt: "",
      step3: false,
      step3_txt: "",
      step_queue: false,
      final_queue: false,
      current_ip: "219.91.239.22",
      close_timer: false,
      timeralert: null,
      success_confirm: false,
      alert_confirm: false,
    };
    // this.handleStep1Change = this.handleStep1Change.bind(this);
    // this.handleStep2Change = this.handleStep2Change.bind(this);
    // this.handleStep3Change = this.handleStep3Change.bind(this);
    // this.saveEmail.bind(this);
  }

  // saveEmail = (e) => {
  //   this.setState({ emailtxt: e.target.value });
  // };
  // handleStep1Change(e) {
  //   this.setState({ step1_txt: e.target.value });
  // }

  // handleStep2Change(e) {
  //   this.setState({ step2_txt: e.target.value });
  // }

  // handleStep3Change(e) {
  //   this.setState({ step3_txt: e.target.value });
  // }

  stimer() {
    const getTimer = () => (
      <SweetAlert
        title={
          <span>
            HTML <small>AutoClose Timer</small>!
          </span>
        }
        onConfirm={() => this.hideTimeAlert()}
      >
        <span>Timer Will Expire after 3 Seconds.</span>
      </SweetAlert>
    );
    this.setState({ timeralert: getTimer() });
    setTimeout(
      function () {
        this.setState({ timeralert: null });
      }.bind(this),
      3000
    );
  }
  hideTimeAlert() {
    this.setState({ timeralert: null });
  }

  async AddGroup() {
    //  let dispatch = useDispatch()
    // let vehiclesArray = [];

    try {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      const groups = await AddGroups(accessToken);
      if (groups.status === true) {
        this.props.getNewGroup(groups);

        Swal.fire({
          position: "top",
          icon: "success",
          title: "New Group Added Successfully",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  render() {
    return (
      <div className="d-flex justify-content-end">
        {/* Button trigger modal */}
        {/* <div
          className=" text-end  col-2 mb-3"
          style={{
            // position: "-webkit-sticky",
            position: "sticky",
            top: "1rem",
          }}
        > */}
        <button
          type="button"
          style={{
            background: "linear-gradient(#3E94A6, #323F4B)",
            color: "white",
          }}
          className="btn position-fixed"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add Group
        </button>
        {/* </div> */}
        {/* Modal */}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center">
                <h1>Are you Sure!</h1>
                <p>You Want To Add Group</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  style={{
                    background: "linear-gradient(#3E94A6, #323F4B)",
                    color: "white",
                    color: "white",
                  }}
                  className="btn "
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropOne"
                  onClick={this.props.AddGroup}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                {/* //////////////////////////////// */}
                {/* <div>
                
                  <div
                    className="modal fade"
                    id="staticBackdropOne"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">
                            Add New Group
                          </h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                          <div className="d-flex justify-content-around">
                            <div className="d-flex align-items-center">
                              <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0 me-1">
                                <b>Detail</b>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-solid "
                                placeholder=" Detail Title"
                               />
                              {console.log("plateNumber", this.state.plateNumber)}
                            </div>
                            <div className="w-50 mx-2 d-flex align-items-center">
                              <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0 me-1">
                                <b>Size</b>
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
     >
                                <option selected className="">
                                  Choose Size
                                </option>
                                <option value="Male">1</option>
                                <option value="Female">2</option>
                                <option value="Other">3</option>
                              </select>
                            </div>
                          </div>
                          <div className="d-flex justify-content-around mt-3">
                            <div className="d-flex align-items-center">
                              <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0 me-1">
                                <b>Price</b>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-solid "
                                placeholder=" Detail Title"
                                />
                            </div>
                            <div className="d-flex align-items-center w-50 mx-2">
                              <label htmlFor="exampleFormControlInput1" className=" ms-2 fw-bolder required form-label mb-0 me-1">
                                <b>Per</b>
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected className="">
                                  Time Slot
                                </option>
                                <option value="Female">Days</option>
                                <option value="Other">Week</option>
                                <option value="Male">Month</option>
                                <option value="Other">Year</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                          </button>
                          <button
                            type="button"
                            style={{
                              background: "linear-gradient(#3E94A6, #323F4B)",
                              color: "white",
                            }}
                            className="btn "
                          >
                            Add Group
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* //////////////////////////////// */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = () => {};

export default AddDivButton;

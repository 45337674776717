import E from "react-script";
import { BaseURL } from "../BaseURL";

export const GetDashBoard = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/deal-details`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

export const getAllInvoices = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/approve-deals/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

import { BaseURL } from "../BaseURL";

export const getVehicles = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/vehicles?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const AddVehicles = async (accessToken, vehicleName) => {
  const response = await fetch(`${BaseURL}/admin/vehicles`, {
    body: JSON.stringify({
      name: vehicleName,
    }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditVehicle = async (accessToken, vehicleName, id) => {
  const response = await fetch(`${BaseURL}/admin/vehicles/${id}`, {
    body: JSON.stringify({
      name: vehicleName,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const deleteVehicle = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/vehicles/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const searchVehicles = async (accessToken, vehicle) => {
  const response = await fetch(`${BaseURL}/admin/vehicles/search`, {
    body: JSON.stringify({
      search: vehicle,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
// ..//////////////////// vhicle   Activ deactive

export const ActiveVehicleID = async (accessToken, vehicleID) => {
  const response = await fetch(`${BaseURL}/admin/vehicles/active/${vehicleID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const DeActiveVehicleID = async (accessToken, vehicleID) => {
  const response = await fetch(`${BaseURL}/admin/vehicles/deactive/${vehicleID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

import { BaseURL } from "../BaseURL";

export const createPackage = async (
   title,
   description,
   oneMonthValue,
   threeMonthValue,
   sixMonthValue,
   nineMonthValue,
   yearValue,
   status,
   packageType,
   oneMonthStatus,
   threeMonthStatus,
   sixMonthStatus,
   nineMonthStatus,
   oneYearStatus,
   featureType,
   isFeatureTypeModal,
   numberOfListings,
   numberOfListingsID,
   oneParkingSpots,
   oneParkingSpotsID,
   oneParkingSpotsPhotos,
   oneParkingSpotsPhotosID,
   ParkingSpotsFeatured,
   ParkingSpotsFeaturedID,
   hot,
   hotID,
   superHot,
   superHotID,
   FiveParkingSpotsPhotos,
   FiveParkingSpotsPhotosID,
   tenParkingSpots,
   tenParkingSpotsID,
   TenParkingSpotsPhotos,
   TenParkingSpotsPhotosID,
   oneMonthParkingStatus,
   oneMonthParkingStatusPhotos,
   fiveMonthParkingStatus,
   fiveMonthParkingStatusPhotos,
   tenMonthParkingStatus,
   tenMonthParkingStatusPhotos,
   accessToken
) => {
   let feature = [
      {
         id: numberOfListingsID,
         value: numberOfListings == "" ? "Unlimited" : numberOfListings,
      },
      // {
      //    id: oneParkingSpotsID,
      //    value:
      //       oneParkingSpots == 0 || oneParkingSpots == ""
      //          ? null
      //          : oneParkingSpots,
      // },
      {
         id: oneParkingSpotsPhotosID,
         value:
            oneParkingSpotsPhotos == 0 || oneParkingSpotsPhotos == ""
               ? null
               : oneParkingSpotsPhotos,
      },
      {
         id: ParkingSpotsFeaturedID,
         value:
            ParkingSpotsFeatured == 0 || ParkingSpotsFeatured == ""
               ? null
               : ParkingSpotsFeatured,
      },
      {
         id: hotID,
         value: hot == 0 || hot == "" ? null : hot,
      },
      {
         id: superHotID,
         value: superHot == 0 || superHot == "" ? null : superHot,
      },
   ];

   const response = await fetch(`${BaseURL}/admin/package`, {
      body: JSON.stringify({
         package_type: title,
         description: description,
         month_price: oneMonthValue,
         six_month_price: sixMonthValue,
         year_price: yearValue,
         month_status: oneMonthStatus,
         six_month_status: sixMonthStatus,
         year_status: oneYearStatus,
         status: status,
         features: feature,
      }),
      method: "POST",
      headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         Authorization: "Bearer " + accessToken,
      },
   });

   const result = await response.json();
   console.log(result);
   return result;
};
export const getPackageFeature = async (accessToken) => {
   const response = await fetch(`${BaseURL}/admin/package/feature`, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
      },
   });
   const result = await response.json();
   return result;
};
export const getPackageTitle = async (accessToken) => {
   const response = await fetch(`${BaseURL}/package/type`, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
      },
   });
   const result = await response.json();
   return result;
};
export const getPackages = async (accessToken, page) => {
   const response = await fetch(`${BaseURL}/admin/package?page=${page}`, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
      },
   });
   const result = await response.json();
   return result;
};
export const getPermission = async (accessToken) => {
   const response = await fetch(`${BaseURL}/package/get-all-permission`, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
      },
   });
   const result = await response.json();
   return result;
};
export const deletePackage = async (accessToken, id) => {
   const response = await fetch(`${BaseURL}/admin/package/${id}`, {
      method: "DELETE",
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
      },
   });
   const result = await response.json();
   return result;
};
export const updatePackage = async (
   title,
   description,
   oneMonthValue,
   threeMonthValue,
   sixMonthValue,
   nineMonthValue,
   yearValue,
   status,
   packageType,
   oneMonthStatus,
   threeMonthStatus,
   sixMonthStatus,
   nineMonthStatus,
   oneYearStatus,
   featureType,
   isFeatureTypeModal,
   numberOfListings,
   numberOfListingsID,
   oneParkingSpots,
   oneParkingSpotsID,
   oneParkingSpotsPhotos,
   oneParkingSpotsPhotosID,
   ParkingSpotsFeatured,
   ParkingSpotsFeaturedID,
   hot,
   hotID,
   superHot,
   superHotID,
   FiveParkingSpotsPhotos,
   FiveParkingSpotsPhotosID,
   tenParkingSpots,
   tenParkingSpotsID,
   TenParkingSpotsPhotos,
   TenParkingSpotsPhotosID,
   oneMonthParkingStatus,
   oneMonthParkingStatusPhotos,
   fiveMonthParkingStatus,
   fiveMonthParkingStatusPhotos,
   tenMonthParkingStatus,
   tenMonthParkingStatusPhotos,
   id,
   accessToken
) => {
   let feature = [
      {
         id: numberOfListingsID,
         value: numberOfListings == "" ? "Unlimited" : numberOfListings,
      },
      // {
      //    id: oneParkingSpotsID,
      //    value:
      //       oneParkingSpots == 0 || oneParkingSpots == ""
      //          ? null
      //          : oneParkingSpots,
      // },
      {
         id: oneParkingSpotsPhotosID,
         value:
            oneParkingSpotsPhotos == 0 || oneParkingSpotsPhotos == ""
               ? null
               : oneParkingSpotsPhotos,
      },
      {
         id: ParkingSpotsFeaturedID,
         value:
            ParkingSpotsFeatured == 0 || ParkingSpotsFeatured == ""
               ? null
               : ParkingSpotsFeatured,
      },
      {
         id: hotID,
         value: hot == 0 || hot == "" ? null : hot,
      },
      {
         id: superHotID,
         value: superHot == 0 || superHot == "" ? null : superHot,
      },
   ];
   const response = await fetch(`${BaseURL}/admin/package/${id}`, {
      body: JSON.stringify({
         package_type: title,
         description: description,
         month_price: oneMonthValue,
         six_month_price: sixMonthValue,
         year_price: yearValue,
         month_status: oneMonthStatus,
         six_month_status: sixMonthStatus,
         year_status: oneYearStatus,
         status: status,
         features: feature,
      }),
      method: "POST",
      headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         Authorization: "Bearer" + "  " + accessToken,
      },
   });
   const result = await response.json();
   return result;
};

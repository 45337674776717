import { BaseURL } from "../BaseURL";

export const AddFeatures = async (accessToken, name, description,icon) => {
  const form_data = new FormData();
  form_data.append("name", name);
  form_data.append("description", description);
  form_data.append("feature_image", icon);

  const response = await fetch(`${BaseURL}/admin/parking-features`, {
    body: form_data,
    method: "POST",
    headers: {
     
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

export const getVehicleFeature = async (accessToken, page) => {
  const response = await fetch(`${BaseURL}/admin/parking-features?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const EditFeature = async (accessToken, Name, description, id,icon) => {
  const form_data = new FormData();
  form_data.append("name", Name);
  form_data.append("description", description);
  form_data.append("feature_image", icon);
  const response = await fetch(`${BaseURL}/admin/parking-features/${id}`, {
    body: form_data,
    method: "POST",
    headers: {
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();

  return result;
};

export const deleteVehicleFeature = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/parking-features/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();

  return result;
};

export const setActiveFeature = async (accessToken, spotID) => {
  const response = await fetch(`${BaseURL}/admin/parking-features/active/${spotID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
export const setDeActiveFeature = async (accessToken, spotID) => {
  const response = await fetch(`${BaseURL}/admin/parking-features/deactive/${spotID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
